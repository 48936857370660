import React, { Component, useState, useRef, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Sidebar/header";
import Sidebar from "../../Sidebar/sidebarAdmin";
import Select from "react-select";
import { element } from "prop-types";
import deleteicon from "../../../assets/img/profiles/delete-bin-line.svg";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import axios from "axios";
import { useEffect } from "react";
import Swal from "sweetalert2";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
// import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { HeightRounded, UploadFile } from "@mui/icons-material";
import { css, jsx } from "@emotion/react";
import Spinner from "@atlaskit/spinner";
import Button from "@atlaskit/button/standard-button";
import moment from "moment";
import { read, utils } from "xlsx";
import { Table, Popconfirm, Row, Col, Icon, Upload } from "antd";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Salary from "./Salary";
import { set } from "react-hook-form";
import BasicSalary from "./BasicSalary";
import { makeStyles } from "@material-ui/core/styles";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    color: "white",
  },
}));
// import { borderTopRightRadius } from "html2canvas/dist/types/css/property-descriptors/border-radius";

const boldStyles = css({
  fontWeight: "bold",
});

const AddEmployee = () => {
  const classes = useStyles();
  const [menu, setMenu] = useState(false);
  const [step, setStep] = useState(0);
  const [ctctype, setCtctype] = useState(0);
  const [deptlist, setDeptList] = useState([]);
  const [desilist, setDesiList] = useState([]);
  const [adminlist, setAdminList] = useState([]);
  const [offices, setOffices] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [hierarchies, setHierarchies] = useState([]);
  const [typeemp, settypeEmp] = useState("Salaried");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [UploadFile, setUploadFile] = useState({});
  const [progresswidth, setProgressWidth] = useState(0);
  const [intial, setIntial] = useState(false);
  const [errorcheck, setErrorCheck] = useState([]);
  const [showPer, setShowPer] = useState(false);
  const [sumCtc, setSumCtc] = useState(0);
  const [roleList, setRoleList] = useState([]);
  const [perList, setPerList] = useState([]);
  const [userPer, setUserPer] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [exData, setExData] = useState({});
  const [allData, setAllData] = useState({});
  const [showPre, setShowPre] = useState(true);
  const [showPreCTC, setShowPreCTC] = useState(false);
  const [salData, setSalData] = useState({
    monthly_ctc: 0,
    annual_ctc: 0,
    monthly_gross: 0,
    annual_gross: 0,
    monthly_net: 0,
    annual_net: 0,
  });

  // const admid = JSON.parse(sessionStorage.getItem("admid"));
  const open = Boolean(anchorEl);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);
  const [earn, setEarn] = useState([]);
  const [Ded, setDed] = useState([]);
  const [varia, setVaria] = useState([]);
  const [contractor, setContractor] = useState({
    name: "",
    conct_no: "",
    spoke: "",
  });
  let currentdate = new Date();
  // let eighteenYearsAgo = new Date(currentdate.setMonth(currentdate.getMonth() - 2));
  let eighteenYearsAgo = new Date(
    currentdate.setFullYear(currentdate.getFullYear() - 18)
  );
  console.log(
    `${eighteenYearsAgo.getFullYear()}-${
      eighteenYearsAgo.getMonth() + 1
    }-${eighteenYearsAgo.getDate()}`
  );
  const [contractors, setContractors] = useState([]);
  const navigate = useNavigate();
  const closeModal = useCallback(() => setIsOpen(false), []);
  const [employeeAdd, setEmployeeAdd] = useState({
    official_info: {
      name: "",
      middle_name: "",
      last_name: "",
      dob: "",
      gender: "",
      off_mobile_number: "",
      email: "",
      address: "",
      shift_from: "09:00:00",
      shift_to: "18:00:00",
      empl_type: "",
      doj: "",
    },
    user_detail: {
      comp_id: sessionStorage.getItem("coid"),
      office: 99,
      dept_id: "",
      sub_dept: "",
      desg_id: "",
      report_manager: "",
      shift_id: "",
      hie_id: "",
      role: "",
      contractor_id: "",
      empcode: "",
    },
    personal_info: {
      passport_number: "",
      passport_exp_date: "",
      nationality: "",
      blood_grp: "",
      marital_status: "",
      per_ph_number: "",
      emp_of_spouse: "",
      number_of_children: 0,
      alt_ph_number: "",
      aadhar_number: "",
      permanant_address: "",
      state: "",
      district: "",
      pincode: "",
    },
    bank_details: {
      bank_name: "",
      acc_number: "",
      IFSC_code: "",
      PAN_number: "",
    },
    emergency_cont: [
      {
        relation: "",
        name: "",
        ph_number: "",
        email: "",
        blood_grp: "",
      },
    ],
    education: [
      {
        degree_name: "",
        specialization: "",
        institution: "",
        from: "",
        to: "",
      },
    ],
    experience: [
      {
        company_name: "",
        designation: "",
        experience_in_years: 0,
        from: "",
        to: "",
      },
    ],
    CTC: {
      Anual_ctc: 0,
      empl_type: "",
      earning: [
        {
          name: "",
          annual_amt: "",
        },
      ],
      deduction: [
        {
          name: "",
          annual_amt: "",
        },
      ],
    },
  });
  const [contact, setContact] = useState([
    {
      relation: "",
      name: "",
      ph_number: "",
      email: "",
      blood_grp: "",
    },
  ]);
  const [education, setEducation] = useState([
    {
      degree_name: "",
      specialization: "",
      institution: "",
      from: "",
      to: "",
    },
  ]);

  const [experience, setExperience] = useState([
    // {
    //   company_name: "",
    //   designation: "",
    //   experience_in_years: "",
    //   from: "",
    //   to: "",
    // },
  ]);

  const [fixedEarning, setFixedEarning] = useState([
    {
      name: "Basic Salary",
      calculation: "",
      monthly: "",
      Annual_amt: 0,
    },
    {
      name: "House Rent Allowance(HRA)",
      calculation: "",
      monthly: "",
      Annual_amt: 0,
    },
  ]);

  const [otherEarning, setOtherEarning] = useState([
    {
      name: "",
      calculation: "",
      monthly: "",
      Annual_amt: 0,
    },
  ]);

  /*--------------------------------------*/
  const [totalCtc, setTotalCtc] = useState(0);
  const [fixedPer, setFixedPer] = useState(0);
  const [fixed, setFixed] = useState(0);
  const [totalSum, setTotalSum] = useState(0);
  const [dummy, setDummy] = useState(true);
  const [otherAllow, setOtherAllow] = useState(0);
  const [otherDeduct, setOtherDeduct] = useState(0);
  const [excelData, setExcelData] = useState([]);
  const [enrg, setEnrg] = useState([
    {
      key: 1,
      name: "Basic",
      type: "percent",
      value: 50,
      monthly: 0,
      annually: 0,
      enabled: true,
    },
    {
      key: 2,
      name: "HRA",
      type: "percent",
      value: 20,
      monthly: 0,
      annually: 0,
      enabled: true,
    },
    {
      key: 5,
      name: "Special Alowances",
      type: "percent",
      value: 30,
      monthly: 0,
      annually: 0,
      enabled: true,
    },
    {
      key: 3,
      name: "WiFi Allowance",
      type: "value",
      value: 1200,
      monthly: 0,
      annually: 0,
      enabled: true,
    },
  ]);

  const [othea, setOthea] = useState([
    {
      name: "Other/Special Allowance",
      type: "-",
      value: "-",
      monthly: 0,
      annually: 0,
      enabled: false,
    },
  ]);
  const [otheV, setOtheV] = useState([
    {
      name: "Other Variables",
      type: "-",
      value: "-",
      monthly: 0,
      annually: 0,
    },
  ]);

  const [deduct, setDeduct] = useState([
    {
      key: 1,
      name: "Professional Tax",
      type: "value",
      value: 500,
      monthly: 0,
      annually: 0,
      enabled: true,
    },
    {
      key: 2,
      name: "Other Tax",
      type: "percent",
      value: 5,
      monthly: 0,
      annually: 0,
      enabled: true,
    },
  ]);

  const [variables, setVariables] = useState([
    {
      key: 1,
      name: "Performance Variable",
      type: "value",
      value: 500,
      monthly: 0,
      annually: 0,
      enabled: true,
    },
    {
      key: 2,
      name: "Annual Retaintion Variable",
      type: "percent",
      value: 5,
      monthly: 0,
      annually: 0,
      enabled: true,
    },
  ]);
  const handleFormChange12 = (index, event, state, setState) => {
    let data = [...state];
    data[index]["isActive"] = !data[index]["isActive"];
    // data[index][event] = event.value;
    setState(data);
  };

  const fileHandler = (event) => {
    let fileObj = event.target.files[0];

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        setExcelData({
          cols: resp.cols,
          rows: resp.rows,
        });
      }
    });
  };

  const readUploadFile = (e) => {
    // e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        setExcelData(json);
        setShowTable(true);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
      setExData(e.target.files[0]);
    }
  };

  const HandleDelete = () => {
    setShowTable(false);
    setExData({});
    setExcelData([]);
  };

  /*--------------------------------------------*/

  const genderData = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  const emptype = [
    {
      label: "Fulltime",
      value: "Fulltime",
    },
    {
      label: "Permanent",
      value: "Permanent",
    },
    {
      label: "Probationer",
      value: "Probationer",
    },
    {
      label: "Part-time",
      value: "Part-time",
    },
    {
      label: "Contractual",
      value: "Contractual",
    },
    {
      label: "Trainee",
      value: "Trainee",
    },
    {
      label: "Intern",
      value: "Intern",
    },
    {
      label: "Temporary",
      value: "Temporary",
    },
  ];

  const natinal = [
    {
      label: "Indian",
      value: "Indian",
    },
  ];

  const bloodgroup = [
    {
      label: "A+",
      value: "A+",
    },
    {
      label: "A-",
      value: "A-",
    },
    {
      label: "B+",
      value: "B+",
    },
    {
      label: "B-",
      value: "B-",
    },
    {
      label: "O+",
      value: "O+",
    },
    {
      label: "O-",
      value: "O-",
    },
    {
      label: "AB+",
      value: "AB+",
    },
    {
      label: "AB-",
      value: "AB-",
    },
  ];

  const marital = [
    {
      label: "Unmarried",
      value: "Unmarried",
    },
    {
      label: "married",
      value: "married",
    },
  ];

  const relations = [
    {
      label: "Mother",
      value: "Mother",
    },
    {
      label: "Father",
      value: "Father",
    },
  ];
  const bloodGroup = [{ label: "A+", value: "A+" }];

  useEffect(() => {
    if (employeeAdd.user_detail.role !== "") {
      const data = perList.find(
        (ele) => ele.id === employeeAdd.user_detail.role
      );
      setUserPer(data?.perm_ids);
    }
  }, [employeeAdd.user_detail.role]);

  const Step0 = () => {
    setStep(0);
    setEmployeeAdd({
      official_info: {
        name: "",
        dob: "",
        gender: "",
        off_mobile_number: "",
        email: "",
        address: "",
        shift_from: "09:00:00",
        shift_to: "18:00:00",
        empl_type: "",
        shift_id: 2,
        doj: "",
        overtime_applicable: false,
      },
      user_detail: {
        comp_id: sessionStorage.getItem("coid"),
        office: "",
        dept_id: "",
        sub_dept: "",
        desg_id: "",
        report_manager: "",
        shift_id: "",
        hie_id: "",
        contractor_id: "",
      },
      personal_info: {
        passport_number: "",
        passport_exp_date: "",
        nationality: "",
        blood_grp: "",
        marital_status: "",
        per_ph_number: "",
        emp_of_spouse: "",
        number_of_children: 0,
        alt_ph_number: "",
        aadhar_number: "",
        permanant_address: "",
        state: "",
        district: "",
        pincode: "",
      },
      bank_details: {
        bank_name: "",
        acc_number: "",
        IFSC_code: "",
        PAN_number: "",
      },
      emergency_cont: [
        {
          relation: "",
          name: "",
          ph_number: "",
          email: "",
          blood_grp: "",
        },
      ],
      education: [
        {
          degree_name: "",
          specialization: "",
          institution: "",
          from: "",
          to: "",
        },
      ],
      experience: [
        {
          company_name: "",
          designation: "",
          experience_in_years: 0,
          from: "",
          to: "",
        },
      ],
      CTC: {
        Anual_ctc: 0,
        empl_type: "",
        earning: [
          {
            name: "",
            annual_amt: "",
          },
        ],
        deduction: [
          {
            name: "",
            annual_amt: "",
          },
        ],
      },
    });
  };

  const steps = [
    "Personal Details",
    // "Bank Details",
    // "Education Details",
    "Salary Details",
  ];
  /* To calculate Sum of CTC breakup */
  useEffect(() => {
    const sum = fixedEarning.reduce((accumulator, object) => {
      return accumulator + object.monthly * 12;
    }, 0);
    setSumCtc(sum);
  }, [fixedEarning]);
  const token = sessionStorage.getItem("access_token");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/roles/`, {
        params: {
          // empid: sessionStorage.getItem("emp_id"),
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // setDeptList(res.data);
        setPerList(res.data);
        setRoleList(
          res.data.map((ele) => ({
            label: ele.name,
            value: ele.id,
          }))
        );
      });
  }, []);

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_BACKEND_URL}/hierarchy/`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       setHierarchies(res.data);
  //     });
  // }, []);
  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_BACKEND_URL}/offices/`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       setOffices(res.data);
  //     });
  // }, []);

  /*  get dasignation*/
  const handleGetDes = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tran_designation/`, {
        params: {
          draft: 1,
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDesiList(
          res.data
            .filter((item) => item.desg !== null)
            .map((ele) => ({
              label: ele.desg.name,
              value: ele.desg.desg_id,
            }))
        );
        // setExdes(res.data);
        // setGetDes(res.data);
        // setIndustrytype(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleGetDes();
  }, []);
  /*  get heirarchy*/

  const handleHierarchi = (arr) => {
    const filter1 = arr.filter(
      (obj, index) =>
        arr.findIndex((item) => item.value === obj.value) === index
    );
    setHierarchies(filter1);
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tran_hierarchy/`, {
        params: {
          draft: 1,
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // setHeir(res.data);
        // setHierarchies(
        //   res.data.hie.filter(
        //     (obj, index) =>
        //       res.data.hie.findIndex((item) => item.hie_id === obj.hie_id) ===
        //       index
        //   )
        // );
        // setHierarchies(
        //   res.data.map((ele) => ({
        //     label: ele.hie.name,
        //     value: ele.hie.hie_id,
        //   }))
        // );
        // setIndustrytype(res.data);
        handleHierarchi(
          res.data.map((ele) => ({
            label: ele.hie.name,
            value: ele.hie.hie_id,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /* get Department */
  const handleGetDept = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tran_department/`, {
        params: {
          draft: 1,
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // setGetDept(res.data);
        setDeptList(
          res.data.map((ele) => ({
            label: ele.dept.name,
            value: ele.dept.dept_id,
          }))
        );
        // setIndustrytype(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetDept();
  }, []);

  console.log(employeeAdd);

  /* get offices */
  const handleGetOff = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tran_co_office/`, {
        params: {
          draft: 1,
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // setOfficeDetails(res.data);
        setOffices(
          res.data.map((ele) => ({
            label: `${ele.name}-${ele.city}`,
            value: ele.off_id,
          }))
        );
        //     });
        // setOfficeTable(res.data);
        // setIndustrytype(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetOff();
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/list_admins/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAdminList(res.data);
      });
  }, []);
  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_BACKEND_URL}/designation/`, {
  //       params: {
  //         empid: sessionStorage.getItem("emp_id"),
  //       },
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       setDesiList(res.data);
  //     });
  // }, []);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/shift/`, {
        params: { coregid: sessionStorage.getItem("coid"), draft: 1 },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // setShifts(res.data);
        setShifts(
          res.data.map((ele) => ({
            label: ele.name,
            value: ele.id,
          }))
        );
      });
  }, []);

  const handleExcelDownload = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/bulk_upload/`, {
        params: {
          coregid: sessionStorage.getItem("coid"),
          dev: `dev`,
        },
        responseType: "blob", // Set responseType to 'blob'
      })
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `mass_upload_template.xlsx`); // Ensure the correct file extension
        link.setAttribute("target", `_blank`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.error("Error downloading the file:", err);
      });
  };
  

  const getMaxDate = () => {
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    // Format the date as "YYYY-MM-DD" required by the `date` input type
    const maxDate = eighteenYearsAgo.toISOString().split("T")[0];
    return maxDate;
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const addFields = (e, state, setState) => {
    e.preventDefault();
    let newfield = {
      relation: "",
      name: "",
      ph_number: "",
      email: "",
      blood_grp: "",
    };
    let edufield = {
      degree_name: "",
      specialization: "",
      institution: "",
      from: "",
      to: "",
    };
    let expField = {
      company_name: "",
      designation: "",

      experience_in_years: "",
      from: "",
      to: "",
    };
    let earnField = {
      name: "",
      calculation: "",
      monthly: "",
      Annual_amt: 0,
    };

    setState([
      ...state,
      state === contact
        ? newfield
        : state === education
        ? edufield
        : state === experience
        ? expField
        : earnField,
    ]);
  };

  const removeFields = (index, state, setState) => {
    let data = [...state];
    data.splice(index, 1);
    setState(data);
  };

  const validation = [
    {
      label: "First Name: Only Text",
    },
    {
      label: "Last Name: Only Text",
    },
    {
      label: "Date of Birth: dd-mm-yyyy",
    },
    {
      label: "Gender: male/female/other",
    },
    { label: "Mobile Number: 10 digits" },

    { label: "Address 1: Alpha Numeric" },
    {
      label: "Address 2: Alpha Numeric",
    },
    { label: "State : Alpha Numeric" },
    { label: "Pincode: Number with 6 digit" },
    { label: "Email: email" },
    { label: "Relation: Alpha Numeric" },
    { label: "Name: Alpha Numeric" },
    { label: "Mobile Number2: 10 digits" },
    { label: "Email2: email" },
    { label: "Blood Group: Alpha Numeric" },
    { label: "Bank Name: Only Text" },
    { label: "Account Number: Numeric" },
    { label: "IFSC Code: Alpha Numeric" },
    { label: "PAN Number: Numeric with 10 digit" },
    { label: "Passport Number: Alpha Numeric with length 8" },
    { label: "Office: Only Text" },
    { label: "Nationality: Only Text" },
    { label: "Passport Expiry Date: Date in dd-mm-yyyy format" },
    { label: "Marital Status: Only Text" },
    { label: "Spouse Phone Number: 10 digits" },
    { label: "Employeement of Spouse: Alpha Numeric" },
    { label: "Number of Children: Numeric" },
    { label: "Alternate Phone Number: 10 digits" },
    { label: "Aadhar Number: Numeric with 12 digits" },
    { label: "Permanent Adress: Only Text" },
    { label: "District: Alpha Numeric" },
    { label: "Shift From: Time in format HH:MM:SS" },
    { label: "Shift To: Time in format HH:MM:SS" },
  ];

  const handleEmployeeRegistration = () => {
    let timerInterval;
    Swal.fire({
      title: "Please wait for a while",
      html: "Records Is getting saved",
      // timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector("b");
        timerInterval = setInterval(() => {
          b.textContent = Swal.getTimerLeft();
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("I was closed by the timer");
      }
    });
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/employee_full_details/`,
        {
          //   official_info: employeeAdd.official_info,
          //   user_detail: employeeAdd.user_detail,
          //   personal_info: employeeAdd.personal_info,
          //   bank_details: employeeAdd.bank_details,
          //   emergency_cont: contact,
          //   education: education,
          //   experience: experience,
          name:
            employeeAdd.official_info.name +
            " " +
            employeeAdd.official_info.middle_name +
            " " +
            employeeAdd.official_info.last_name,
          email: employeeAdd.official_info.email,
          empl_type: employeeAdd.official_info.empl_type,
          PAN_number: employeeAdd.bank_details.PAN_number?.toUpperCase(),
          off_mobile_number: employeeAdd.official_info.off_mobile_number,
          dob: employeeAdd.official_info.dob,
          date_joined: employeeAdd.official_info.doj,
          gender: employeeAdd.official_info.gender,
          comp_id: sessionStorage.getItem("coid"),
          office: employeeAdd.user_detail.office,
          report_manager: employeeAdd.user_detail.report_manager,
          contractor_id: employeeAdd.user_detail.contractor_id,
          overtime_applicable: employeeAdd.user_detail.overtime_applicable,
          // office: 99,
          dept_id: employeeAdd.user_detail.dept_id,
          sub_dept: employeeAdd.user_detail.sub_dept,
          desg_id: employeeAdd.user_detail.desg_id,
          hie_id: employeeAdd.user_detail.hie_id,
          shift_id: employeeAdd.user_detail.shift_id,
          aadhar_number: employeeAdd.personal_info.aadhar_number,
          permission: userPer
            .filter((ele) => ele.isActive === true)
            .map((item) => item.id),
          role: employeeAdd.user_detail.role,
          empcode: employeeAdd.user_detail.empcode,
          CTC:
            // ctctype === 0
            //   ?
            {
              // Anual_ctc: totalCtc,
              monthly_ctc: allData.monthly_ctc,
              annual_ctc: allData.annual_ctc,
              monthly_gross: allData.monthly_gross,
              annual_gross: allData.annual_gross,
              monthly_net: allData.monthly_net,
              annual_net: allData.annual_net,

              empl_type: employeeAdd.CTC.empl_type,
              earning:
                // fixedEarning.map((ele) => ({
                //   name: ele.name,
                //   annual_amt: ele.monthly * 12,
                // })),
                earn
                  // .concat(othea)
                  .map((ele) => {
                    return {
                      name: ele.name,
                      annual_amt: ele.annually,
                      payslip_visible: ele.visible_payslip,
                    };
                  })
                  .filter((item) => item.name !== "Total Earnings"),

              deduction:
                // otherEarning.map((ele) => ({
                //   name: ele.name,
                //   annual_amt: ele.monthly * 12,
                // })),
                Ded.map((ele) => ({
                  name: ele.name,
                  annual_amt: ele.annually,
                  payslip_visible: ele.visible_payslip,
                })),
              variables: varia
                // .concat(otheV)
                .map((ele) => {
                  return {
                    name: ele.name,
                    annual_amt: ele.annually,
                    payslip_visible: ele.visible_payslip,
                  };
                })
                .filter((item) => item.name !== "Total Variables"),
              //   }
              // : {
              //     // Anual_ctc: totalCtc,
              //     monthly_ctc: salData.monthly_ctc,
              //     annual_ctc: totalCtc,
              //     monthly_gross: salData.monthly_gross,
              //     annual_gross: salData.annual_gross,
              //     monthly_net: salData.monthly_net,
              //     annual_net: salData.annual_net,

              //     empl_type: employeeAdd.CTC.empl_type,
              //     earning:
              //       // fixedEarning.map((ele) => ({
              //       //   name: ele.name,
              //       //   annual_amt: ele.monthly * 12,
              //       // })),
              //       enrg
              //         // .concat(othea)
              //         .map((ele) => {
              //           return {
              //             name: ele.name,
              //             annual_amt: ele.annually,
              //             payslip_visible:
              //               ele.payslip_visible === "Yes" ? 1 : 0,
              //           };
              //         })
              //         .filter((item) => item.name !== "Total Earnings"),

              //     deduction:
              //       // otherEarning.map((ele) => ({
              //       //   name: ele.name,
              //       //   annual_amt: ele.monthly * 12,
              //       // })),
              //       deduct.map((ele) => ({
              //         name: ele.name,
              //         annual_amt: ele.annually,
              //         payslip_visible: ele.payslip_visible === "Yes" ? 1 : 0,
              //       })),
              //     variables: variables
              //       // .concat(otheV)
              //       .map((ele) => {
              //         return {
              //           name: ele.name,
              //           annual_amt: ele.annually,
              //           payslip_visible: ele.payslip_visible === "Yes" ? 1 : 0,
              //         };
              //       })
              //       .filter((item) => item.name !== "Total Variables"),
            },
        }

        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Employee registered successfully",
          timer: 2000,
        });
        navigate("/manageEmployee");
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something Went Wrong",
          });
        }
      });
  };

  const handleFormChange = (index, event, state, setState) => {
    let data = [...state];
    data[index][event.target.name] = event.target.value;
    // data[index][event] = event.value;
    setState(data);
  };

  const handleFormChange1 = (index, event, state, setState, value) => {
    let data = [...state];
    // console.log(index);
    // console.log(event);
    data[index][value] = event.value;
    // data[index][event] = event.value;
    setState(data);
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "transparent",
      // height: "45px",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderRadius: "none",
      borderBottom: "1px solid gray",
      zIndex: 0,
    }),
  };
  const hiddenFileInput = useRef(null);

  const uploadfile = (value) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/excel_upload/`, {
        params: {
          admid: sessionStorage.getItem("admid"),
          toggle: "L",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const formdata = new FormData();
        formdata.append("files", value);
        axios
          .put(res.data.put_request_link, formdata)
          .then(async (response) => {
            // Swal.fire({
            //   icon: "waiting",
            //   title: "Data upload is in progress, Please wait on screen",
            //   timerProgressBar: true,
            //   html:
            //     ` <div className="progress progress-lg">` +
            //     `<div className="progress-bar" role="progressbar" style={{width: '10%'}} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />` +
            //     `</div>`,
            //   didOpen: () => {
            //     Swal.showLoading();
            //   },
            //   width: 600,
            //   padding: "3em",
            //   color: "#716add",
            //   background: "#fff url(/images/trees.png)",
            //   backdrop: ` rgba(0,0,123,0.4 url("/images/nyan-cat.gif") left top no-repeat`,
            // });

            openModal();
            // handleprogress();
            const interval = setInterval(() => {
              // console.log("This will run every half second!");
              // setProgressWidth(progresswidth + 10);
              // setProgressWidth((prevCount) => prevCount + 10);
              axios
                .get(`${process.env.REACT_APP_BACKEND_URL}/excel_upload/`, {
                  params: {
                    // admid: sessionStorage.getItem("admid"),
                    toggle: "W",
                    path: res.data.path_name,
                  },
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((resp) => {
                  setProgressWidth(Math.round(resp.data.percent));
                });
            }, 500);
            setIntial(true);
            await axios
              .post(
                `${process.env.REACT_APP_BACKEND_URL}/excel_upload/`,
                {
                  path: res.data.path_name,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res1) => {
                clearInterval(interval);
                setIntial(false);
                closeModal();

                // const url = window.URL.createObjectURL(
                //   new Blob([
                //     "https://sapio-oz-hrms.s3.amazonaws.com/company/3/mass_upload/exceptions/2022-12-28.xlsx",
                //   ])
                // );
                const link = document.createElement("a");
                link.href = res1.data.exception[0].links;

                link.setAttribute("target", `_blank`);
                link.setAttribute("download", `Exception.xlsx`);
                // link.setAttribute(
                //   "content",
                //   `${res.data.download_template_link[0].content_type}`
                // );
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                Swal.fire({
                  icon: "success",
                  html: `<p>Data Uploaded Successfully, please check exception sheet if there is any exception</p>`,
                  text: res1.data.message,
                  width: 600,
                  padding: "3em",
                  color: "#716add",
                  background: "#fff url(/images/trees.png)",
                  backdrop: ` rgba(0,0,123,0.4 url("/images/nyan-cat.gif") left top no-repeat`,
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    navigate("/manageEmployee");
                  } else if (result.isDenied) {
                    Swal.fire("Changes are not saved", "", "info");
                  }
                });
              })
              .catch((error) => {
                console.log(error);
                Swal.fire({
                  icon: "error",
                  text: "Please upload Correct file",
                  width: 600,
                  padding: "3em",
                  color: "#716add",
                  background: "#fff url(/images/trees.png)",
                  backdrop: ` rgba(0,0,123,0.4 url("/images/nyan-cat.gif") left top no-repeat`,
                });
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClick1 = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const getContractors = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/contractors/`, {
        params: {
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setContractors(
          res.data.map((ele, index) => ({
            value: ele.id,
            label: ele.name,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddContractor = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/contractors/`,
        {
          coregid: sessionStorage.getItem("coid"),
          name: contractor.name,
          mobile: contractor.conct_no,
          spoc_name: contractor.spoke,
        },
        {
          // params: {
          //   empid: sessionStorage.getItem("emp_id"),
          // },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            text: "Contractor added successfully",
            // timer: 1500,
          });
        }
        setContractor({
          name: "",
          conct_no: "",
          spoke: "",
        });
        getContractors();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          //title: "oops..",
          text: err.response?.data?.error
            ? `${
                err.response.data.error[0].toUpperCase() +
                err.response.data.error.slice(1)
              }!`
            : "Something Went Wrong",
          // timer: 1500,
        });
      });
  };

  const handleUploadBulk = () => {
    let formdata = new FormData();
    formdata.append("file", exData);
    formdata.append("coregid", sessionStorage.getItem("coid"));
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/bulk_upload/`, formdata,
        {
          responseType: "blob", // Set response type to blob for binary data
        }
      )
      .then((res) => {
        console.log(res)
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'OnboardingReport.xlsx'); // Specify the file name and extension
        document.body.appendChild(link);
        link.click();
    
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        Swal.fire({
          icon: "success",
          text: "Employees registered successfully",
        });

        HandleDelete();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something Went Wrong",
          });
        }
      });
  };
  useEffect(() => {
    getContractors();
  }, []);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    // uploadfile(fileUploaded);
    // handleUploadBulk(fileUploaded);
    setExData(fileUploaded);
    // props.handleFile(fileUploaded);
    // setUploadFile(event.target.files[0]);
  };

  const handleprogress = () => {
    const interval = setInterval(() => {
      // console.log("This will run every half second!");
      setProgressWidth(progresswidth + 10);
    }, 1000);
    return () => clearInterval(interval);
  };

  const handleproceed = (e) => {
    e.preventDefault();
  };

  /*validation*/
  const handlefirstValidation = async (e, callback) => {
    e.preventDefault();
    setErrorCheck([]);
    const numbers = /^[0-9]+$/;
    const regexExp = /^\d{10}$/;
    const passregex = /^[A-PR-WYa-pr-wy][1-9]\\d\\s?\\d{4}[1-9]$/;
    const regexpin = /^\d{6}$/;
    const Panregex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const regex = /^\d{12}$/;
    const letters = /^[a-zA-Z\s]*$/;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let check = true;
    if (!employeeAdd.official_info.name.match(letters)) {
      setErrorCheck((pre) => pre.concat([1]));
      check = false;
    }
    // if (employeeAdd.official_info.off_mobile_number !== "") {
    //   if (!employeeAdd.official_info.off_mobile_number.match(regexExp)) {
    //     setErrorCheck((pre) => pre.concat([2]));
    //     check = false;
    //   }
    // }
    if (!employeeAdd.official_info.off_mobile_number.match(regexExp)) {
      setErrorCheck((pre) => pre.concat([2]));
      check = false;
    }
    // if (employeeAdd.official_info.email) {
    //   if (!employeeAdd.official_info.email.match(mailformat)) {
    //     setErrorCheck((pre) => pre.concat([3]));
    //     check = false;
    //   }
    // }
    if (!employeeAdd.official_info.email.match(mailformat)) {
      if (sessionStorage.getItem("coid") !== "Ram 067802") {
        setErrorCheck((pre) => pre.concat([3]));
        check = false;
      }
      // setErrorCheck((pre) => pre.concat([3]));
      // check = false;
    }
    if (employeeAdd.official_info.name === "") {
      setErrorCheck((pre) => pre.concat([4]));
      check = false;
    }
    if (employeeAdd.official_info.last_name === "") {
      if (sessionStorage.getItem("coid") !== "Ram 067802") {
        setErrorCheck((pre) => pre.concat([5]));
        check = false;
      }
      // setErrorCheck((pre) => pre.concat([5]));
      // check = false;
    }
    // if (!employeeAdd.personal_info.passport_number.match(passregex)) {
    //   setErrorCheck((pre) => pre.concat([4]));
    //   check = false;
    // }
    // if (!employeeAdd.personal_info.per_ph_number.match(regexExp)) {
    //   setErrorCheck((pre) => pre.concat([5]));
    //   check = false;
    // }

    // if (employeeAdd.personal_info.emp_of_spouse !== "") {
    //   if (!employeeAdd.personal_info.emp_of_spouse.match(letters)) {
    //     setErrorCheck((pre) => pre.concat([6]));
    //     check = false;
    //   }
    // }

    // if (!employeeAdd.personal_info.number_of_children.match(numbers)) {
    //   setErrorCheck((pre) => pre.concat([7]));
    //   check = false;
    // }

    // if (employeeAdd.personal_info.alt_ph_number !== "") {
    //   if (!employeeAdd.personal_info.alt_ph_number.match(regexExp)) {
    //     setErrorCheck((pre) => pre.concat([8]));
    //     check = false;
    //   }
    // }
    if (!employeeAdd.personal_info.aadhar_number.match(regex)) {
      setErrorCheck((pre) => pre.concat([9]));
      check = false;
    }
    // if (employeeAdd.personal_info.state) {
    //   if (!employeeAdd.personal_info.state.match(letters)) {
    //     setErrorCheck((pre) => pre.concat([10]));
    //     check = false;
    //   }
    // }
    // if (employeeAdd.personal_info.district !== "") {
    //   if (!employeeAdd.personal_info.district.match(letters)) {
    //     setErrorCheck((pre) => pre.concat([11]));
    //     check = false;
    //   }
    // }
    // if (employeeAdd.personal_info.pincode !== "") {
    //   if (!employeeAdd.personal_info.pincode.match(regexpin)) {
    //     setErrorCheck((pre) => pre.concat([12]));
    //     check = false;
    //   }
    // }

    // if (employeeAdd.user_detail.role === "") {
    //   setErrorCheck((pre) => pre.concat([12]));
    //   check = false;
    // }
    if (!employeeAdd.bank_details.PAN_number?.toUpperCase().match(Panregex)) {
      console.log(employeeAdd.bank_details.PAN_number);
      setErrorCheck((pre) => pre.concat([16]));
      check = false;
    }
    if (employeeAdd.official_info.dob === "") {
      setErrorCheck((pre) => pre.concat([17]));
      check = false;
    }
    if (employeeAdd.official_info.gender === "") {
      setErrorCheck((pre) => pre.concat([18]));
      check = false;
    }
    if (employeeAdd.user_detail.office === "") {
      setErrorCheck((pre) => pre.concat([19]));
      check = false;
    }
    if (employeeAdd.user_detail.report_manager === "") {
      setErrorCheck((pre) => pre.concat([20]));
      check = false;
    }
    if (employeeAdd.user_detail.dept_id === "") {
      setErrorCheck((pre) => pre.concat([21]));
      check = false;
    }
    if (employeeAdd.user_detail.desg_id === "") {
      setErrorCheck((pre) => pre.concat([22]));
      check = false;
    }

    if (employeeAdd.user_detail.shift_id === "") {
      setErrorCheck((pre) => pre.concat([23]));
      check = false;
    }
    if (employeeAdd.user_detail.hie_id === "") {
      setErrorCheck((pre) => pre.concat([24]));
      check = false;
    }
    if (employeeAdd.official_info.email === "") {
      if (sessionStorage.getItem("coid") !== "Ram 067802") {
        setErrorCheck((pre) => pre.concat([25]));
        check = false;
      }
      // setErrorCheck((pre) => pre.concat([25]));
      // check = false;
    }
    if (employeeAdd.official_info.off_mobile_number === "") {
      setErrorCheck((pre) => pre.concat([26]));
      check = false;
    }
    if (employeeAdd.official_info.empl_type === "") {
      setErrorCheck((pre) => pre.concat([27]));
      check = false;
    }
    handlemove(check);
  };

  const handleSecondValidation = (e) => {
    e.preventDefault();
    setErrorCheck([]);
    const numbers = /^[0-9a-zA-Z]{0,25}$/;
    const regexExp = /^\d{10}$/;
    const passregex = /^[A-PR-WYa-pr-wy][1-9]\\d\\s?\\d{4}[1-9]$/;
    const regexpin = /^\d{6}$/;
    const ifscregex = /^[0-9a-zA-Z]{11}$/;

    const regex = /^\d{12}$/;
    const letters = /^[a-zA-Z\s]*$/;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let check = true;
    if (!employeeAdd.bank_details.bank_name.match(letters)) {
      setErrorCheck((pre) => pre.concat([13]));
      check = false;
    }
    if (!employeeAdd.bank_details.acc_number.match(numbers)) {
      setErrorCheck((pre) => pre.concat([14]));
      check = false;
    }
    if (!employeeAdd.bank_details.IFSC_code.match(ifscregex)) {
      setErrorCheck((pre) => pre.concat([15]));
      check = false;
    }

    handlemove(check);
  };

  const handlemove = (value) => {
    if (value === true) {
      setStep(step + 1);
      setShowPre(false);
    } else {
      alert("please enter Valid inputs");
    }
  };

  return (
    <div
      className={`main-wrapper ${menu ? "slide-nav" : ""}`}
      //   style={{ marginRight: "20px", overflowX: "unset" }}
    >
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        {!showTable ? (
          <div>
            <div
              className="content container-fluid" /*style={{ marginTop: "-10px" }}*/
            >
              <br />
              <br />
              <div className="page-header">
                <div className="row">
                  <div className="col">
                    <h3 className="page-title">Add Employee</h3>
                  </div>
                  <div className="col-auto float-end ml-auto">
                    <div className="view-icons">
                      <button
                        className=" "
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "16px 15px",
                          fontSize: "15px",
                          background: "#3298DB",
                          border: "none",
                          color: "white",
                          height: "30px",
                          borderRadius: "8px",
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#add_task_board"
                        href="#"
                        classname="edit-icon"
                        // onClick={() => setApplyLeave("wfh")}
                      >
                        <Box>
                          <Tooltip title="Add Employees in Bulk">
                            <IconButton
                              onClick={handleClick}
                              size="small"
                              color="white"
                              // sx={{ ml: -3 }}
                              aria-controls={open ? "account-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              // className={classes.button}
                            >
                              <p
                                style={{
                                  fontSize: "14px",
                                  marginTop: "15px",
                                  color: "white",
                                  fontWeight: "500",
                                }}
                              >
                                <b>+</b> Add Bulk Employees
                              </p>
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </button>
                      <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&:before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem>
                          <a
                            onClick={() => handleExcelDownload()}
                            // href={`${
                            //   process.env.REACT_APP_BACKEND_URL
                            // }/bulk_upload/?coregid=${sessionStorage.getItem(
                            //   "coid"
                            // )}`}
                            download
                            target="_blank"
                          >
                            Download Template
                          </a>
                        </MenuItem>
                        <MenuItem>
                          <a
                            onClick={(e) => handleClick1(e)}
                            // data-bs-toggle="modal"
                            // data-bs-target="#experience_info"
                            // href="#"
                            // appearance="primary"
                            // onClick={openModal}
                          >
                            Upload File
                          </a>
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              //   className="content container-fluid"
              style={{
                // marginTop: "-55px",
                height: "100%",
                // background: "white",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
              }}
            >
              {/* Page Header */}

              {/* /Page Header */}
              {/* Content Starts */}
              <form>
                <div className=" col-sm-12">
                  <div
                    className="col-sm-12  "
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="col-sm-6" classname={classes.container}>
                      <Box sx={{ width: "100%" }}>
                        <Stepper activeStep={step} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel style={{ color: "#FFFFFF" }}>
                                {label}
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Box>
                    </div>
                  </div>
                  <br />
                  <div>
                    {step === 0 && (
                      <form>
                        <div
                          //   className="row"
                          //   style={{
                          //     justifyContent: "space-between",
                          //   }}
                          className=" col-sm-12 row"
                        >
                          <div className=" col-sm-2"></div>
                          <div className=" col-sm-8">
                            <div
                              className="card-body"
                              style={{
                                background: "white",
                                borderRadius: "10px",
                              }}
                            >
                              <h4
                                className="heading-add"
                                style={{ textAlign: "start" }}
                              >
                                <b style={{ fontSize: "20px" }}>
                                  Personal Details
                                </b>
                                <span style={{ fontSize: "10px" }}>
                                  {" "}
                                  {/* (All field are mandatory) */}
                                </span>
                              </h4>
                              <div
                                className="row"
                                style={{ justifyContent: "center" }}
                              >
                                <div className="form-group col-sm-3">
                                  <label> First Name</label>

                                  <input
                                    type="text"
                                    placeholder="eg. Vaibhav"
                                    className="form-control input-add add-emp-field"
                                    value={employeeAdd.official_info.name}
                                    onChange={(e) =>
                                      setEmployeeAdd({
                                        ...employeeAdd,
                                        official_info: {
                                          ...employeeAdd.official_info,
                                          name: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                  {errorcheck.find((ele) => ele === 4) && (
                                    <p className="error">
                                      This field is mandatory
                                    </p>
                                  )}
                                </div>
                                <div className="form-group col-sm-3">
                                  <label> Middle Name</label>

                                  <input
                                    type="text"
                                    placeholder="eg. Vinayak"
                                    className="form-control input-add add-emp-field"
                                    value={
                                      employeeAdd.official_info.middle_name
                                    }
                                    onChange={(e) =>
                                      setEmployeeAdd({
                                        ...employeeAdd,
                                        official_info: {
                                          ...employeeAdd.official_info,
                                          middle_name: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                  {/* {errorcheck.find((ele) => ele === 24) && (
                                    <p className="error1">
                                      This field is mandatory
                                    </p>
                                  )} */}
                                </div>
                                <div className="form-group col-sm-3">
                                  <label> Last Name</label>

                                  <input
                                    type="text"
                                    className="form-control input-add add-emp-field"
                                    placeholder="eg. Maske"
                                    value={employeeAdd.official_info.last_name}
                                    onChange={(e) =>
                                      setEmployeeAdd({
                                        ...employeeAdd,
                                        official_info: {
                                          ...employeeAdd.official_info,
                                          last_name: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                  {errorcheck.find((ele) => ele === 5) && (
                                    <p className="error">
                                      This field is mandatory
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ justifyContent: "center" }}
                              >
                                <div className="form-group col-sm-3">
                                  <label> Date of Birth</label>
                                  <input
                                    type="date"
                                    className="form-control  input-add add-emp-field"
                                    value={employeeAdd.official_info.dob}
                                    // max={`${eighteenYearsAgo.getFullYear()}-${
                                    //   eighteenYearsAgo.getMonth() + 1
                                    // }-${eighteenYearsAgo.getDate()}`}
                                    max={getMaxDate()}
                                    onChange={(e) =>
                                      setEmployeeAdd({
                                        ...employeeAdd,
                                        official_info: {
                                          ...employeeAdd.official_info,
                                          dob: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                  {errorcheck.find((ele) => ele === 17) && (
                                    <p className="error">
                                      This field is mandatory
                                    </p>
                                  )}
                                </div>
                                <div className="form-group col-sm-3">
                                  <label>Gender</label>
                                  <Select
                                    // styles={colourStyles}
                                    className="add-emp-field"
                                    options={genderData}
                                    value={
                                      employeeAdd.official_info.gender !== ""
                                        ? {
                                            label:
                                              employeeAdd.official_info.gender,
                                            value:
                                              employeeAdd.official_info.gender,
                                          }
                                        : null
                                    }
                                    onChange={(e) =>
                                      setEmployeeAdd({
                                        ...employeeAdd,
                                        official_info: {
                                          ...employeeAdd.official_info,
                                          gender: e.value,
                                        },
                                      })
                                    }
                                  />
                                  {errorcheck.find((ele) => ele === 18) && (
                                    <p className="error1">
                                      This field is mandatory
                                    </p>
                                  )}
                                </div>
                                <div className="form-group col-sm-3">
                                  <label> Date of Joining</label>
                                  <input
                                    type="date"
                                    className="form-control  input-add add-emp-field"
                                    value={employeeAdd.official_info.doj}
                                    max={moment().format("YYYY-MM-DD")}
                                    onChange={(e) =>
                                      setEmployeeAdd({
                                        ...employeeAdd,
                                        official_info: {
                                          ...employeeAdd.official_info,
                                          doj: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                  {/* {errorcheck.find((ele) => ele === 17) && (
                                    <p className="error">
                                      This field is mandatory
                                    </p>
                                  )} */}
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ justifyContent: "center" }}
                              >
                                <div className="form-group col-sm-4">
                                  <label>Phone Number</label>
                                  <input
                                    pattern="[0-9]{10}"
                                    minlength="10"
                                    title="please Enter Valid Phone Number"
                                    maxlength="10"
                                    type="tel"
                                    className="form-control input-add add-emp-field"
                                    value={
                                      employeeAdd.official_info
                                        .off_mobile_number
                                    }
                                    onChange={(e) =>
                                      setEmployeeAdd({
                                        ...employeeAdd,
                                        official_info: {
                                          ...employeeAdd.official_info,
                                          off_mobile_number: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                  {/* {errorcheck.find((ele) => ele === 26) && (
                                    <p className="error1 error2">
                                      This field is mandatory
                                    </p>
                                  )} */}
                                  {errorcheck.find((ele) => ele === 2) && (
                                    <p className="error">
                                      Enter Valid Phone Number
                                    </p>
                                  )}
                                </div>
                                <div className="form-group col-sm-4">
                                  <label>Email</label>
                                  <input
                                    type="email"
                                    className="form-control input-add add-emp-field"
                                    value={employeeAdd.official_info.email}
                                    onChange={(e) =>
                                      setEmployeeAdd({
                                        ...employeeAdd,
                                        official_info: {
                                          ...employeeAdd.official_info,
                                          email: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                  {/* {errorcheck.find((ele) => ele === 25) && (
                                    <p className="error1">
                                      This field is mandatory
                                    </p>
                                  )} */}
                                  {errorcheck.find((ele) => ele === 3) && (
                                    <p className="error">Enter Valid Email</p>
                                  )}
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ justifyContent: "center" }}
                              >
                                <div className="form-group col-sm-4">
                                  <label>Pan Card Number</label>
                                  <input
                                    type="text"
                                    maxlength="10"
                                    className="form-control input-add add-emp-field"
                                    value={employeeAdd.bank_details.PAN_number?.toUpperCase()}
                                    onChange={(e) =>
                                      setEmployeeAdd({
                                        ...employeeAdd,
                                        bank_details: {
                                          ...employeeAdd.bank_details,
                                          PAN_number: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                  {errorcheck.find((ele) => ele === 16) && (
                                    <p className="error">
                                      Enter Valid PAN number
                                    </p>
                                  )}
                                </div>
                                <div className="form-group col-sm-4">
                                  <label>Aadhar Number</label>
                                  <input
                                    pattern="[0-9]{12}"
                                    minlength="12"
                                    title="please Enter Valid Aadhar Number"
                                    maxlength="12"
                                    type="tel"
                                    className="form-control input-add add-emp-field"
                                    value={
                                      employeeAdd.personal_info.aadhar_number
                                    }
                                    onChange={(e) =>
                                      setEmployeeAdd({
                                        ...employeeAdd,
                                        personal_info: {
                                          ...employeeAdd.personal_info,
                                          aadhar_number: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                  {errorcheck.find((ele) => ele === 9) && (
                                    <p className="error">
                                      Enter valid aadhar number
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <br />
                            <br />
                          </div>

                          <div
                            className=" row"
                            style={{ marginLeft: "3px", padding: "3px" }}
                          >
                            <div className="col-sm-2"></div>
                            <div className="col-sm-8">
                              <div
                                className="card-body"
                                style={{
                                  background: "white",
                                  borderRadius: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <h4
                                    className="heading-add"
                                    style={{ textAlign: "start" }}
                                  >
                                    <b>Job Roles and Details</b>
                                    <span style={{ fontSize: "10px" }}>
                                      {" "}
                                      {/* (All field are mandatory) */}
                                    </span>
                                  </h4>
                                  {employeeAdd.official_info?.empl_type ===
                                    "Contractual" && (
                                    <div>
                                      <button
                                        className="btn btn-success btn-block "
                                        style={{
                                          padding: "2px 10px",
                                          fontSize: "13px",
                                          background: "#3298db",
                                          border: "none",
                                          color: "white",
                                          height: "25px",
                                          borderRadius: "10px",
                                        }}
                                        data-bs-toggle="modal"
                                        data-bs-target="#add_contractor"
                                        href="#"
                                        classname="edit-icon"
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <i className="fa fa-plus" /> &nbsp;
                                        Contractor
                                      </button>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="row"
                                  style={{ justifyContent: "center" }}
                                >
                                  <div className="form-group col-sm-4">
                                    <label>Department</label>
                                    <Select
                                      // styles={colourStyles}
                                      className="add-emp-field"
                                      options={deptlist}
                                      value={
                                        employeeAdd.user_detail.dept_id !== ""
                                          ? deptlist.find(
                                              (ele) =>
                                                ele.value ===
                                                employeeAdd.user_detail.dept_id
                                            )
                                          : null
                                      }
                                      onChange={(e) =>
                                        setEmployeeAdd({
                                          ...employeeAdd,
                                          user_detail: {
                                            ...employeeAdd.user_detail,
                                            dept_id: e.value,
                                          },
                                        })
                                      }
                                    />
                                    {errorcheck.find((ele) => ele === 21) && (
                                      <p className="error1">
                                        This field is mandatory
                                      </p>
                                    )}
                                  </div>
                                  {employeeAdd.user_detail?.dept_id !== "" && (
                                    <div className="form-group col-sm-4">
                                      <label>Sub-Department</label>
                                      <Select
                                        // styles={colourStyles}
                                        className="add-emp-field"
                                        options={
                                          desilist.find(
                                            (item) =>
                                              item.value ===
                                              employeeAdd.user_detail.desg_id
                                          )?.sub_dept
                                        }
                                        value={
                                          employeeAdd.user_detail.sub_dept !==
                                          ""
                                            ? desilist
                                                .find(
                                                  (item) =>
                                                    item.value ===
                                                    employeeAdd.user_detail
                                                      .desg_id
                                                )
                                                ?.sub_dept.find(
                                                  (ele) =>
                                                    ele.value ===
                                                    employeeAdd.user_detail
                                                      .sub_dept
                                                )
                                            : null
                                        }
                                        onChange={(e) =>
                                          setEmployeeAdd({
                                            ...employeeAdd,
                                            user_detail: {
                                              ...employeeAdd.user_detail,
                                              sub_dept: e.value,
                                            },
                                          })
                                        }
                                      />
                                    </div>
                                  )}

                                  <div className="form-group col-sm-4">
                                    <label>Designation</label>
                                    <Select
                                      // styles={colourStyles}
                                      className="add-emp-field"
                                      options={desilist}
                                      value={
                                        employeeAdd.user_detail.desg_id !== ""
                                          ? desilist.find(
                                              (ele) =>
                                                ele.value ===
                                                employeeAdd.user_detail.desg_id
                                            )
                                          : null
                                      }
                                      onChange={(e) =>
                                        setEmployeeAdd({
                                          ...employeeAdd,
                                          user_detail: {
                                            ...employeeAdd.user_detail,
                                            desg_id: e.value,
                                          },
                                        })
                                      }
                                    />
                                    {errorcheck.find((ele) => ele === 22) && (
                                      <p className="error1">
                                        This field is mandatory
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <br />
                                <div
                                  className="row"
                                  style={{ justifyContent: "center" }}
                                >
                                  <div className="form-group col-sm-4">
                                    <label>Reporting Manager </label>
                                    <Select
                                      // styles={colourStyles}
                                      className="add-emp-field"
                                      options={adminlist}
                                      value={
                                        employeeAdd.user_detail
                                          .report_manager !== ""
                                          ? adminlist.find(
                                              (ele) =>
                                                ele.value ===
                                                employeeAdd.user_detail
                                                  .report_manager
                                            )
                                          : null
                                      }
                                      onChange={(e) =>
                                        setEmployeeAdd({
                                          ...employeeAdd,
                                          user_detail: {
                                            ...employeeAdd.user_detail,
                                            report_manager: e.value,
                                          },
                                        })
                                      }
                                    />
                                    {errorcheck.find((ele) => ele === 20) && (
                                      <p className="error1">
                                        This field is mandatory
                                      </p>
                                    )}
                                  </div>
                                  <div className="form-group col-sm-4">
                                    <label>Office/Location</label>
                                    <Select
                                      // styles={colourStyles}
                                      className="add-emp-field"
                                      options={offices}
                                      value={
                                        employeeAdd.user_detail.office !== ""
                                          ? offices.find(
                                              (ele) =>
                                                ele.value ===
                                                employeeAdd.user_detail.office
                                            )
                                          : null
                                      }
                                      onChange={(e) =>
                                        setEmployeeAdd({
                                          ...employeeAdd,
                                          user_detail: {
                                            ...employeeAdd.user_detail,
                                            office: e.value,
                                          },
                                        })
                                      }
                                    />
                                    {errorcheck.find((ele) => ele === 19) && (
                                      <p className="error1">
                                        This field is mandatory
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <br />
                                <div
                                  className="row"
                                  style={{ justifyContent: "center" }}
                                >
                                  <div className="form-group col-sm-4">
                                    <label>Employment Type</label>
                                    <Select
                                      // styles={colourStyles}
                                      className="add-emp-field"
                                      options={emptype}
                                      value={
                                        employeeAdd.official_info.empl_type !==
                                        ""
                                          ? {
                                              label:
                                                employeeAdd.official_info
                                                  .empl_type,
                                              value:
                                                employeeAdd.official_info
                                                  .empl_type,
                                            }
                                          : null
                                      }
                                      onChange={(e) =>
                                        setEmployeeAdd({
                                          ...employeeAdd,
                                          official_info: {
                                            ...employeeAdd.official_info,
                                            empl_type: e.value,
                                          },
                                        })
                                      }
                                    />
                                    {errorcheck.find((ele) => ele === 27) && (
                                      <p className="error1">
                                        This field is mandatory
                                      </p>
                                    )}
                                  </div>
                                  {employeeAdd.official_info?.empl_type ===
                                    "Contractual" && (
                                    <div className="form-group col-sm-4">
                                      <label>Contractor Name</label>
                                      <Select
                                        // styles={colourStyles}
                                        className="add-emp-field"
                                        // options={[
                                        //   ...new Set(
                                        //     hierarchies?.map((ele) => ({
                                        //       label: ele.name,
                                        //       value: ele.hie_id,
                                        //     }))
                                        //   ),
                                        // ]}
                                        options={contractors}
                                        // options={[...new Set(hierarchies)]}
                                        value={
                                          employeeAdd.user_detail
                                            .contractor_id !== ""
                                            ? contractors.find(
                                                (ele) =>
                                                  ele.value ===
                                                  employeeAdd.user_detail
                                                    .contractor_id
                                              )
                                            : null
                                        }
                                        onChange={(e) =>
                                          setEmployeeAdd({
                                            ...employeeAdd,
                                            user_detail: {
                                              ...employeeAdd.user_detail,
                                              contractor_id: e.value,
                                            },
                                          })
                                        }
                                      />
                                    </div>
                                  )}
                                  <div className="form-group col-sm-4">
                                    <label>Hierarchy/Grade</label>
                                    <Select
                                      // styles={colourStyles}
                                      className="add-emp-field"
                                      // options={[
                                      //   ...new Set(
                                      //     hierarchies?.map((ele) => ({
                                      //       label: ele.name,
                                      //       value: ele.hie_id,
                                      //     }))
                                      //   ),
                                      // ]}
                                      options={hierarchies.filter(
                                        (item, index) =>
                                          hierarchies.indexOf(item.hie_id) !==
                                          index
                                      )}
                                      // options={[...new Set(hierarchies)]}
                                      value={
                                        employeeAdd.user_detail.hie_id !== ""
                                          ? hierarchies.find(
                                              (ele) =>
                                                ele.value ===
                                                employeeAdd.user_detail.hie_id
                                            )
                                          : null
                                      }
                                      onChange={(e) =>
                                        setEmployeeAdd({
                                          ...employeeAdd,
                                          user_detail: {
                                            ...employeeAdd.user_detail,
                                            hie_id: e.value,
                                          },
                                        })
                                      }
                                    />
                                    {errorcheck.find((ele) => ele === 24) && (
                                      <p className="error1">
                                        This field is mandatory
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <br />
                                <div
                                  className="row"
                                  style={{ justifyContent: "left" }}
                                >
                                  <div className="col-sm-2"></div>
                                  <div className="form-group col-sm-4">
                                    <label>
                                      Shift {/*<sup className="sup">*</sup> */}
                                    </label>
                                    <Select
                                      // styles={colourStyles}
                                      className="add-emp-field"
                                      // options={[
                                      //   ...new Set(shifts?.map((item) => item)),
                                      // ].map((ele) => ({
                                      //   label: ele.name,
                                      //   value: ele.id,
                                      // }))}
                                      options={shifts}
                                      value={
                                        employeeAdd.user_detail.shift_id !== ""
                                          ? shifts.find(
                                              (ele) =>
                                                ele.value ===
                                                employeeAdd.user_detail.shift_id
                                            )
                                          : null
                                      }
                                      onChange={(e) =>
                                        setEmployeeAdd({
                                          ...employeeAdd,
                                          user_detail: {
                                            ...employeeAdd.user_detail,
                                            shift_id: e.value,
                                          },
                                        })
                                      }
                                    />
                                    {errorcheck.find((ele) => ele === 23) && (
                                      <p className="error1">
                                        This field is mandatory
                                      </p>
                                    )}
                                  </div>
                                  <div className="form-group col-sm-4">
                                    <label>
                                      Role {/*<sup className="sup">*</sup> */}
                                    </label>
                                    <Select
                                      // styles={colourStyles}
                                      className="add-emp-field"
                                      options={roleList}
                                      value={
                                        employeeAdd.user_detail.role !== ""
                                          ? roleList.find(
                                              (ele) =>
                                                ele.value ===
                                                employeeAdd.user_detail.role
                                            )
                                          : null
                                      }
                                      onChange={(e) =>
                                        setEmployeeAdd({
                                          ...employeeAdd,
                                          user_detail: {
                                            ...employeeAdd.user_detail,
                                            role: e.value,
                                          },
                                        })
                                      }
                                    />
                                    {errorcheck.find((ele) => ele === 12) && (
                                      <p className="error1">
                                        This field is mandatory
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <br />
                                <div
                                  className="row"
                                  style={{ justifyContent: "left" }}
                                >
                                  <div className="col-sm-2"></div>
                                  <div className="form-group col-sm-4">
                                    <label>
                                      Employee Code{" "}
                                      {/*<sup className="sup">*</sup> */}
                                    </label>
                                    <input
                                      // pattern="[0-9]{12}"
                                      // minlength="12"
                                      // title="please Enter Valid Aadhar Number"
                                      // maxlength="12"
                                      type="text"
                                      className="form-control input-add add-emp-field"
                                      value={employeeAdd.user_detail.empcode}
                                      onChange={(e) =>
                                        setEmployeeAdd({
                                          ...employeeAdd,
                                          user_detail: {
                                            ...employeeAdd.user_detail,
                                            empcode: e.target.value,
                                          },
                                        })
                                      }
                                    />
                                    {/* {errorcheck.find((ele) => ele === 23) && (
                                      <p className="error1">
                                        This field is mandatory
                                      </p>
                                    )} */}
                                  </div>
                                  <div className="form-group col-sm-4">
                                    <label>
                                      Additional Employee Code{" "}
                                      {/*<sup className="sup">*</sup> */}
                                    </label>
                                    <input
                                      // pattern="[0-9]{12}"
                                      // minlength="12"
                                      // title="please Enter Valid Aadhar Number"
                                      // maxlength="12"
                                      type="text"
                                      className="form-control input-add add-emp-field"
                                      // value={employeeAdd.user_detail.empcode}
                                      // onChange={(e) =>
                                      //   setEmployeeAdd({
                                      //     ...employeeAdd,
                                      //     user_detail: {
                                      //       ...employeeAdd.user_detail,
                                      //       empcode: e.target.value,
                                      //     },
                                      //   })
                                      // }
                                    />
                                    {/* {errorcheck.find((ele) => ele === 23) && (
                                      <p className="error1">
                                        This field is mandatory
                                      </p>
                                    )} */}
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={{ justifyContent: "left" }}
                                >
                                  <div className="col-sm-2"></div>
                                  <div className="col-sm-6">
                                    <input
                                      type="checkbox"
                                      id="vehicle1"
                                      name="vehicle1"
                                      value={
                                        employeeAdd.user_detail
                                          .overtime_applicable
                                      }
                                      onChange={(e) =>
                                        setEmployeeAdd({
                                          ...employeeAdd,
                                          user_detail: {
                                            ...employeeAdd.user_detail,
                                            overtime_applicable:
                                              !employeeAdd.user_detail
                                                .overtime_applicable,
                                          },
                                        })
                                      }
                                    />
                                    &nbsp;&nbsp;
                                    <label for="vehicle1">
                                      Overtime Applicable{" "}
                                    </label>
                                  </div>
                                </div>
                                <br />
                                {/* <div className="form-group col-sm-12">
                                  <p
                                    onClick={() => setShowPer(!showPer)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {" "}
                                    {!showPer
                                      ? `Show Additional Permissions`
                                      : `Hide Additional Permissions`}{" "}
                                  </p>
                                </div>
                                <div className="form-group col-sm-12 row">
                                  {showPer &&
                                    userPer.map((ele, index) => (
                                      <div className="form-group col-sm-6">
                                        <li className="list-group-item col-sm-12 ">
                                          <div
                                            className="status-toggle"
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            {ele.name}
                                            <input
                                              type="checkbox"
                                              id={ele.id}
                                              className="check"
                                              name={ele.name}
                                              checked={ele.isActive}
                                              onChange={(event) =>
                                                handleFormChange12(
                                                  index,
                                                  event,
                                                  userPer,
                                                  setUserPer
                                                )
                                              }
                                            />
                                            <label
                                              htmlFor={ele.id}
                                              className="checktoggle"
                                            >
                                              checkbox
                                            </label>
                                          </div>
                                        </li>
                                      </div>
                                    ))}
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>

                  <div>
                    {step === 1 && (
                      <div
                        className="col-md-12 "
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "12px",
                        }}
                      >
                        <input
                          type="radio"
                          id="basic"
                          name="fav_language"
                          style={{ width: "20px", cursor: "pointer" }}
                          checked={ctctype === 0 ? true : false}
                          onClick={() => {
                            setCtctype(0);
                            setShowPre(false);
                          }}
                        />{" "}
                        &nbsp; &nbsp; &nbsp;
                        <label htmlFor="basic">
                          <span style={{ color: "white" }}>Based on Basic</span>
                        </label>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp;
                        <input
                          type="radio"
                          id="ctc"
                          name="fav_language"
                          style={{ width: "20px", cursor: "pointer" }}
                          checked={ctctype === 1 ? true : false}
                          onClick={() => {
                            setCtctype(1);
                            setShowPre(true);
                          }}
                        />{" "}
                        &nbsp; &nbsp; &nbsp;
                        <label htmlFor="ctc">
                          <span style={{ color: "white" }}>Based on CTC</span>
                        </label>
                        <br />
                      </div>
                    )}
                    {step === 1 && ctctype === 1 && (
                      <Salary
                        totalCtc={totalCtc}
                        setTotalCtc={setTotalCtc}
                        fixed={fixed}
                        setFixed={setFixed}
                        otheV={otheV}
                        othea={othea}
                        setOtheV={setOtheV}
                        setOthea={setOthea}
                        totalSum={totalSum}
                        setTotalSum={setTotalSum}
                        otherAllow={otherAllow}
                        otherDeduct={otherDeduct}
                        enrg={enrg}
                        setEnrg={setEnrg}
                        dummy={dummy}
                        setDummy={setDummy}
                        variables={variables}
                        setVariables={setVariables}
                        deduct={deduct}
                        setDeduct={setDeduct}
                        fixedPer={fixedPer}
                        setFixedPer={setFixedPer}
                        setOtherAllow={setOtherAllow}
                        salData={salData}
                        setSalData={setSalData}
                        allData={allData}
                        setAllData={setAllData}
                        showPreCTC={showPreCTC}
                        setShowPreCTC={setShowPreCTC}
                        earn={earn}
                        setEarn={setEarn}
                        Ded={Ded}
                        setDed={setDed}
                        setVaria={setVaria}
                        varia={varia}
                      />
                    )}
                    {step === 1 && ctctype === 0 && (
                      <BasicSalary
                        totalCtc={totalCtc}
                        setTotalCtc={setTotalCtc}
                        fixed={fixed}
                        setFixed={setFixed}
                        otheV={otheV}
                        othea={othea}
                        setOtheV={setOtheV}
                        setOthea={setOthea}
                        totalSum={totalSum}
                        setTotalSum={setTotalSum}
                        otherAllow={otherAllow}
                        otherDeduct={otherDeduct}
                        enrg={enrg}
                        setEnrg={setEnrg}
                        dummy={dummy}
                        setDummy={setDummy}
                        variables={variables}
                        setVariables={setVariables}
                        deduct={deduct}
                        setDeduct={setDeduct}
                        fixedPer={fixedPer}
                        setFixedPer={setFixedPer}
                        setOtherAllow={setOtherAllow}
                        allData={allData}
                        setAllData={setAllData}
                        showPre={showPre}
                        setShowPre={setShowPre}
                        earn={earn}
                        setEarn={setEarn}
                        Ded={Ded}
                        setDed={setDed}
                        setVaria={setVaria}
                        varia={varia}
                      />
                    )}
                  </div>
                </div>
              </form>

              <div>
                <br />
                {/* {(showPre || showPreCTC) && ( */}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {step > 0 && (
                    <button
                      className="btn-sub"
                      style={{ background: "white", color: "#3298DB" }}
                      onClick={() => setStep(step - 1)}
                    >
                      Previous
                    </button>
                  )}
                  <button
                    className="btn-sub"
                    style={{ background: "white", color: "#3298DB" }}
                    // onClick={() => handleProfileEdit()}
                    onClick={Step0}
                  >
                    Discard
                  </button>
                  {step < 1 ? (
                    <button
                      type="submit"
                      className="btn-sub"
                      style={{ background: "#3298DB", color: "white" }}
                      onClick={(e) =>
                        step == 0
                          ? handlefirstValidation(e)
                          : handleSecondValidation(e)
                      }
                    >
                      Proceed
                    </button>
                  ) : (
                    <button
                      className="btn-sub"
                      style={{ background: "#3298DB", color: "white" }}
                      onClick={() => handleEmployeeRegistration()}
                    >
                      Save
                    </button>
                  )}
                </div>
                {/* )} */}
                <br />
              </div>
            </div>
          </div>
        ) : (
          <>
            <br />
            <br />
            <br />
            <div className="card  pre-table">
              <div className="table-responsive">
                <p>
                  To edit the details, please correct it in the excel file and
                  reupload the same !
                </p>
                <Table
                  scroll={{ x: 1300 }}
                  dataSource={excelData}
                  pagination={{
                    total: excelData.length,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    showSizeChanger: true,
                    onShowSizeChange: onShowSizeChange,
                    itemRender: itemRender,
                  }}
                  columns={
                    excelData.length !== 0
                      ? Object.keys(excelData[0]).map((ele) => ({
                          title: ele,
                          dataIndex: ele,
                          key: ele,
                        }))
                      : []
                  }
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="btn-sub"
                style={{ background: "white", color: "#3298DB" }}
                onClick={() => HandleDelete()}
              >
                Cancel
              </button>
              <button
                className="btn-sub"
                style={{ background: "#3298DB", color: "white" }}
                onClick={() => handleUploadBulk()}
              >
                Submit
              </button>
            </div>
          </>
        )}

        {/* /Page Content */}
        <div
          id="add_contractor"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <br />
                <br />
                <br />
                <h5 className="modal-title">
                  <b>Add Contractor</b>
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <div className="col-md-12">
                      {" "}
                      <label>
                        Contractor Name <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          value={contractor.name}
                          onChange={(e) =>
                            setContractor({
                              ...contractor,
                              name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <div className="col-md-12">
                      {" "}
                      <label>
                        Contact Number <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control"
                          pattern="[0-9]{10}"
                          minlength="10"
                          title="please Enter Valid Phone Number"
                          maxlength="10"
                          type="tel"
                          value={contractor.conct_no}
                          onChange={(e) =>
                            setContractor({
                              ...contractor,
                              conct_no: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        SPOC Name <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          value={contractor.spoke}
                          onChange={(e) =>
                            setContractor({
                              ...contractor,
                              spoke: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn"
                    style={{
                      background: "#3298DB",
                      color: "white",
                      width: "70px",
                      height: "24px",
                      textAlign: "center",
                      borderRadius: "14px",
                      fontSize: "14px",
                      padding: "2px 7px",
                    }}
                    data-bs-dismiss={
                      !contractor.name ||
                      !contractor.conct_no ||
                      !contractor.spoke
                        ? ""
                        : "modal"
                    }
                    onClick={(event) => handleAddContractor(event)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="experience_info"
          className="modal custom-modal fade"
          role="dialog"
          style={{ zIndex: 1000000 }}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Instructions</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-scroll">
                    <div className="card">
                      <div className="card-body">
                        <ul className="row col-sm-12">
                          {validation.map((ele) => (
                            <li className="col-sm-6">{ele.label}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="submit-section"
                    style={{ justifyContent: "space-between" }}
                  >
                    <button
                      className="btn btn-success btn-block w-50"
                      data-bs-dismiss="modal"
                    >
                      Accept
                    </button>
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={(e) => readUploadFile(e)}
                      style={{ display: "none" }}
                    />
                    <button
                      className="btn btn-success btn-block w-50 "
                      data-bs-dismiss="modal"
                      style={{ background: "transparent", color: "#18838D" }}
                    >
                      Decline
                    </button>
                  </div>
                  {/* <OutTable
                    data={excelData.rows}
                    columns={excelData.cols}
                    tableClassName="ExcelTable2007"
                    tableHeaderRowClass="heading"
                  /> */}
                </form>
              </div>
            </div>
          </div>
        </div>

        <ModalTransition>
          {isOpen && (
            <Modal onClose={closeModal}>
              <ModalHeader>
                <ModalTitle>Data upload Progress</ModalTitle>
              </ModalHeader>
              <ModalBody>
                Data upload is in progress, please wait on screen
                <br />
                <div className="progress progress-lg">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${progresswidth}%` }}
                    aria-valuenow={75}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </ModalBody>
              <ModalFooter></ModalFooter>
            </Modal>
          )}
        </ModalTransition>
      </div>
    </div>
  );
};
export default AddEmployee;
